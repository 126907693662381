* {
	background: #f2f6f0;
	font-family: "Arvo", serif;
}

.gallery-modal > div {
    background: black !important;
}

/*
.thisIsMyImage {
	background: url("bakgrund2.png") no-repeat center center fixed;
}
*/