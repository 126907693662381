.main-header {
  background: #ff9736;
  border: 16px #357219 solid;
  border-radius: 0px 0px 16px 16px;
  margin-bottom: 0;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.main-header {
    border: 8px #357219 solid;
    border-radius: 0px 0px 8px 8px;
	}
}


.header-content {
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  background: none;
  flex-wrap: wrap;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.header-content {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
	}
}

.header-image {
  background: none;
  width: 60%;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.header-image {
    width: 320px;
	}
}


.header-logo {
  background: none;
  height: 100%;
  width: 40%;
}

.header-buttons-box {
  background: none;
  flex-wrap: wrap;
  align-self: flex-end;
  margin-right: 8px;
  margin-bottom: 8px;
}



.header-buttons {
  background: none;
  margin-left: 16px;
  text-decoration: none;
  color: #562707;
  font-size: 20px;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.header-buttons {
    font-size: 18px;
	}
}


.header-buttons:active,
.header-buttons:hover{
  color: black;
  font-weight: bold;
}

.home-button {
  background: none;
}

/* https://stackoverflow.com/questions/11525132/css-centred-header-image 




.header-title {
  margin: 8px;
  text-align: center;
  font-size: 48px;
  font-family: "Fira Sans", sans-serif;
  background: #f58820;
}
*/