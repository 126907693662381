.newspage-main {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.newspage-main {
		flex-direction: column;
	}
}

.newspage-main h1 {
	margin-bottom: 16px;
	font-size: 3em;
	margin-top: 8px;
}

.white-space-fix {
	white-space: pre-wrap;
}

.news-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.news-box {
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 2px solid #989f9b;
}

.news-box-header {
	margin-bottom: 2%;
}

.news-box-header-rendered {
	margin-bottom: 2%;
	font-size: 2em;
	margin-top: 0;
}

.read-more-button {
	background: none;
	color: inherit;
	border: none;
	padding: 0 !important;
	font: inherit;
	cursor: pointer;
}

.read-more-words {
	font-weight: bold;
	font-style: italic;
}

.news-text {
	width: 100%;
	height: auto;
	margin-top: 0;
}

.news-image {
	width: 80%;
	height: auto;
	border: 4px solid black;
	margin-top: 3%;
}

.show-less-text {
	margin-top: 2px;
	font-size: 14px;
	font-style: italic;
}

.photo-text {
	font-size: 80%;
	margin-top: 1%;
	font-style: italic;
}

.specific-news-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.show-less-button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	margin-top: 0.5%;
}

.show-less-button:hover {
	font-style: bold;
}

.preview-news-box {
	border-bottom: 2px solid #989f9b;
}

.preview-text {
	margin-bottom: 8px;
}