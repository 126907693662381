#main-footer {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer-icons {
    margin-right: 24px;
}

.soc-icon {
    margin-left: 4px;
}
.footer-copyright-text {
    margin-right: 24px;
    margin-right: 16px;
    font-size: 12px;
}