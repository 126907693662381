@import "./../../node_modules/react-image-gallery/styles/css/image-gallery.css";

.myllan-foto-main {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 99%;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.myllan-foto-main {
		flex-direction: column;
	}
}

.myllan-foto-content-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.myllan-foto-content-section {
		flex-wrap: wrap;
	}
}

.myllan-foto-textsection {
	width: 70%;
	text-align: center;
}

.image-section {
	width: 480px;
	margin: 0;
	padding: 0;
	justify-content: center;
	text-align: center;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.image-section {
		width: 60%;
	}
}

.image-gallery {
	max-width: 100%;
	border: 2px black solid;
}

.image-gallery.fullscreen-modal {
	max-width: 100% !important;
	max-height: 100%;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.image-gallery {
		margin-bottom: 16px;
	}
}




/* FOLDER STYLES */

.preview-all-folders-section {
}

.preview-folders-box {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}



.preview-folder {
	width: 30%;
	margin-left: 5px;
}

.preview-folder:hover {
	width: 35%;
}

.preview-folder:hover .preview-folder-header {
	font-size: 24px;
}

.preview-folder-link {
	text-decoration: none;
}

.preview-folder-link:visited,
.preview-folder-link:link {
	text-decoration: none;
}

.preview-folder-header {
	font-size: 18px;
	color: black;
	text-decoration: none;
}

.folder-preview-image {
	width: 100%;
	border: 2px black solid;
}

.icon-bar {
	margin-left: 8px;
}