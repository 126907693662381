.main-button-box-component {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 24px;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.main-button-box-component {
		flex-direction: row;
		margin-left: 0;
		width: 100%;
		flex-wrap: wrap;
	}
}

.button-box-component-list-items {
	margin: 16px;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.button-box-component-list-items {
		margin: 8px;
	}
}

.circle-button {
	background: #f58820;
	width: 142px;
	height: 142px;
	border: 26px solid #357219; /* Ration mellan width och bordern är 5.46 */
	border-radius: 50%;
}

.myllan-logo-button {
	width: 120px;
}

.myllan-logo-button:hover {
	opacity: 0.5;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.myllan-logo-button {
		width: 70px;
	}
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.circle-button {
		margin: 8px;
		width: 102px;
		height: 102px;
		border: 18px solid #357219;
	}
}

.circle-button:active,
.circle-button:hover {
	background: rgba(245, 136, 32, 0.5);
	border: none;
}

.circle-text {
	margin: 0;
	background: none;
	text-decoration: none;
	font-family: "Fira Sans", sans-serif;
	font-size: 22px;
	font-weight: bold;
	text-align: center;
}

.footer-cyklande-vanner {
	display: inline-block;
}

.button-cyklande-vanner {
	background: red;
	width: 156px;
	height: 64px; /* Ration är 2.4375 */
	border-radius: 5%;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.button-cyklande-vanner {
		width: 102px;
		height: 50px;
	}
}

.button-cyklande-vanner:active,
.button-cyklande-vanner:hover {
	background: rgba(254, 1, 1, 0.5);
}

.button-cyklande-vanner-text {
	color: black;
	text-align: center;
	font-family: "Alex Brush", cursive;
	background: none;
	font-size: 24px;
	font-weight: bold;
	margin: 0;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.button-cyklande-vanner-text {
		font-size: 20px;
	}
}

.footer-list-item {
	width: 16px;
	height: 16px;
}

.cyklande-vanner-beskrivning {
	text-align: center;
	margin-top: 4;
	width: 80%;
}

.sponsor-list-item {
	text-align: center;
	margin: 8px;
	display: block;
}

.fa {
	padding: 8px;
	font-size: 30px;
	width: 30px;
	text-align: center;
	text-decoration: none;
}

.fa:hover {
	opacity: 0.7;
}

.fa-facebook {
	background: #3b5998;
	color: white;
}
