.contact-page-main {
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.contact-page-main {
		flex-direction: column;
	}
}

.contact-page-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-page-header {
	text-align: center;
	margin-bottom: 12px;
}

.contact-info-text {
	width: 60%;
	flex-wrap: wrap;
	margin-top: 8px;
}

.contact-info-box {
	border: 2px black solid;
	padding-left: 8px;
	border-radius: 5%;
	align-content: flex-end;
	padding-right: 32px;
	text-align: left;
}

.contact-info-box-sub-title {
	border-bottom: 1px solid #000;
	padding-bottom: 3px;
	font-weight: bold;
}

.contact-info-box-text {
	font-size: 16px;
}

.contact-info-box-text-orgnr {
	font-size: 12px;
}