.cyklande-vanner-main {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.cyklande-vanner-main {
		flex-direction: column;
	}
}

.cyklande-vanner-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cyklande-vanner-textsection {
	width: 80%;
	text-align: center;
}
.facebook-icon {
	width: 10%;
}

.facebook-icon:hover {
	opacity: 0.5;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.facebook-icon {
		width: 50px;
	}
}

.cyklande-vanner-foto {
	width: 70%;
	border: 4px solid black;
}

.cyklande-vanner-bildtext {
	margin-top: 4px;
	font-style: italic;
}

.sponsor-list-header {
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.sponsor-list-header {
		margin-left: 8px;
	}
}

.sponsor-list-main {
}

.sponsor-list-infotext {
}

.sponsor-list-items {
}

.sponsor-list-item {
	text-decoration: none;
}

.sponsor-list-item:visited {
	text-decoration: none;
}
