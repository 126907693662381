.main-background {
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.main-background {
		flex-direction: column;
		align-items: center;
	}
}

.main-info-section {
	width: 70%;
}

.main-title {
	margin-top: 32px;
	text-align: center;
	font-family: "Arvo", serif;
	font-size: 32px;
}

.main-text {
	font-family: "Arvo", serif;
	text-align: center;
}

.main-page-logga {
	width: 50%;
}
