.myllan-IT-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 420px) and (max-height: 812px) {
	.myllan-IT-container {
		flex-direction: column;
	}
}

.myllan-IT-text-section {
	text-align: center;
	width: 70%;
	display: flex;
	flex-direction: column;
}

.myllan-IT-list {
	display: flex;
	flex-direction: column;
}

.myllan-IT-list p:before {
	content: "• ";
}
