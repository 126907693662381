.fotoarkiv-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fotoarkiv-text {
    text-align: center;
}

.fotoarkiv-år {
    display: flex;
    flex-direction: row;
}

.fotoarkiv-år-cirkel {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: linear-gradient(135deg, #faad65 0%,#fc8415 100%);
    box-shadow: inset -8px -7px 4px 0px rgba(0,0,0,0.3);
    margin-left: 12px;
    position: relative;
}

.fotoarkiv-år-cirkel:hover {
    height: 170px;
    width: 170px;

}
.fotoarkiv-år-cirkel:active {
    background: #fa7901
}
.fotoarkiv-cirkel-text {
    background: none;
    position: absolute;
    bottom: 50%;
    width: 100%;
    text-align: center;
    font-size: 24px;
}

.preview-all-folders-section {
    text-align: center;
}

.preview-folders-box {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}



.preview-folder {
	width: 30%;
	margin-left: 5px;
}

.preview-folder:hover {
	width: 35%;
}

.preview-folder:hover .preview-folder-header {
	font-size: 24px;
}

.preview-folder-link {
	text-decoration: none;
}

.preview-folder-link:visited,
.preview-folder-link:link {
	text-decoration: none;
}

.preview-folder-header {
	font-size: 18px;
	color: black;
	text-decoration: none;
}

.folder-preview-image {
	width: 100%;
	border: 2px black solid;
}
