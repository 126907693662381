.view-folder-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    width: 99%;
}

.view-folder-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
}


.bnb-gallery {
	background: black !important;
}

.gallery-modal {
	background: black !important;
}

.show-slideshow-button {
	background: #357219;
	box-shadow: 0px 0px 0px transparent;
	border: 0px solid transparent;
	font-size: 30px;
	border-radius: 4px;
	color: whitesmoke;
	text-shadow: 0px 0px 0px transparent;
	border-bottom: 4px;
}

.show-slideshow-button:hover {
	background: #234b10;
}


.image-gallery-folder {
	margin-top: 10px;
}

.image-button-view-folder {
	margin: 2px 2px 2px 2px;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

/* funkar inte eftersom den fortfarande är samma class när man tryckt på den
.image-button-view-folder:hover {
	border: 1px black solid;
} */

.view-image-in-folder-container {
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: none;
}

.view-image-in-folder {
	max-height: 800px;
    max-width: 80%;
	height: auto;
	border: 2px black solid
}

.currently-viewed-image-button {
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
}

.view-image-in-folder-close-button {
	margin-top: 4px;
	background: #357219;
	color: whitesmoke;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	font-size: 32px;
	width: 8%;
	border-radius: 4px;
}

.active-image {
	-webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -ms-filter: blur(2px);
    -o-filter: blur(2px);
    filter: blur(2px);
}

.view-image-in-folder-close-button:hover {
	background: #20460e;
	font-size: 40px;
}